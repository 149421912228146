import { cancelParamSplitterStr } from "../../constants/common.c";
import { CANCEL_PAGE } from "../../constants/path.c";
import { stringToBase64 } from "../../utils/helper";
import { isJapanese } from "./isJapanese";

export const handleCancel = (navigate:any,apiData:any) => {
 const japanse= isJapanese();
  // let data = Boolean(apiData?.cancel_url) ?
  //   `?data=${stringToBase64(apiData?.cancel_url)}###`
  //   : "###";
  let data = Boolean(apiData?.cancel_url) ? `${apiData?.cancel_url}${cancelParamSplitterStr}` : cancelParamSplitterStr;

  if (apiData?.config?.isLanguageSwitcherDisabled) {
    data=`${data}${apiData.config.isLanguageSwitcherDisabled}`
  }  
 let dataStr = stringToBase64(data);
 if(japanse){
  navigate(
    `/jp/${CANCEL_PAGE}?data=${dataStr}`
  );
 } else {
  navigate(
    `/${CANCEL_PAGE}?data=${dataStr}`
  );
 }
};