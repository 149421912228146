import PUBLIC_KEY from "../constants/public-key.c";
import base64 from "base-64";
import utf8 from "utf8";
import { JSEncrypt } from "jsencrypt";
import { CARDINAL_URL } from "../constants/api-path.c";
import i18n from "../i18n";

export const encryptText = (plainText:string) => {
  if (window) {
    // Encrypt with the public key...
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    return encrypt.encrypt(plainText);
  }
};

export const pick = (object:any, keys:any) => {
  return keys.reduce((obj:any, key:any) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key];
    }
    return obj;
  }, {});
};

export const stringToBase64 = (text:string) => {
  let bytes = utf8.encode(text);
  return base64.encode(bytes);
};

export const base64ToString = (encoded:string) => {
  if (!encoded) return "";
  let bytes = base64.decode(encoded);
  return utf8.decode(bytes);
};


export function performDDC(bin:any, jwt:any) {
  let innerHtml =
    "<body onload='document.collectionForm.submit();'>" +
    "<form id='collectionForm' name='collectionForm' method='POST' action='" +
    `${CARDINAL_URL}/V1/Cruise/Collect` +
    "' >" +
    "<input type='hidden' name='Bin' value='" +
    bin +
    "'>" +
    "<input type='hidden' name='JWT' value='" +
    jwt +
    "'>" +
    "</form>" +
    "</body>";

  //set iframe properties
  let iframe = document.createElement("iframe");
  iframe.id = "ddcIframe";
  iframe.width = "0";
  iframe.height = "0";
  iframe.style.visibility = "hidden";
  iframe.style.display = "none";
  iframe.srcdoc = innerHtml;
  document.body.appendChild(iframe);
}

export function performRCP(jwt:any, MID:any) {
  let innerHtml =
    "<body onload='document.challengeForm.submit();'>" +
    "<form id='challengeForm' target='_parent' name='challengeForm' method='POST' action='" +
    `${CARDINAL_URL}/V2/Cruise/StepUp` +
    "' >" +
    "<input type='hidden' name='MD' value='" +
    MID +
    "'>" +
    "<input type='hidden' name='JWT' value='" +
    jwt +
    "'>" +
    "</form>" +
    "</body>";

  //set iframe properties
  let iframe = document.createElement("iframe");
  iframe.id = "challengeIframe";
  iframe.width = "0";
  iframe.height = "0";
  iframe.style.visibility = "hidden";
  iframe.style.display = "none";
  iframe.srcdoc = innerHtml;
  document.body.appendChild(iframe);
}

export function getDate(dateString:any) {
  // Create a new Date object from the provided string
  const dateObj = new Date(dateString);

  // Extract day, month, and year components
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1; // Months are zero-based
  const year = dateObj.getUTCFullYear();

  // Format the components as DD/MM/YYYY
  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export function translateCountries(countries:{
    label: string,
    value: string,
  }[]) {
  return countries.map((item) => {
    return { label: i18n.t(`countryNames.${item.label}`), value: item.value };
  });
}

export function generateDateArray() {
  const result = [];
  const today = new Date();
  const endDate = new Date(today.getFullYear() + 15, 11); // December of the end year

  let currentDate = new Date(today.getFullYear(), today.getMonth());

  while (currentDate <= endDate) {
    const formattedMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const formattedYear = (currentDate.getFullYear() % 100).toString().padStart(2, '0');
    result.push({
      label: `${formattedMonth}/${formattedYear}`,
      value: `${formattedMonth}/${formattedYear}`
    });

    // Move to the next month
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return result;
}

// filtering countries list to return all the countries without countries in array and then adding those on top

export const getNewListTranslatedCountriesOnTop = (COUNTRIES: any, countriesValueToBeShownOnTop?: any) => {
    if (countriesValueToBeShownOnTop && countriesValueToBeShownOnTop?.length > 0) {
      const countriesRemovedFromList = COUNTRIES?.filter((item: any) => !countriesValueToBeShownOnTop?.includes(item.value));
      const countriesToBeShownOnTop = COUNTRIES?.filter((item: any) => countriesValueToBeShownOnTop?.includes(item.value));
      const countriesListWithJapanOnTop = [...countriesToBeShownOnTop, ...countriesRemovedFromList];
      return translateCountries(countriesListWithJapanOnTop);
    } else {
      return translateCountries(COUNTRIES);
    }
  }
