import React from "react";
import SelectLanguage from "../language/SelectLanguage.component";
import PO_Black_Logo from "../../components/assets/new-images/po-black-logo.png";

const HppWrapper = ({ children , apiData }: any) => {

  const hideLanguageSwitcher = apiData?.config?.isLanguageSwitcherDisabled;

  return (
    <div className="wrappers">
      <div className="main-container">
        <center>
          <img
            draggable="false"
            src={PO_Black_Logo}
            alt="po-black-logo"
            className="po-logo"
          />
          {!hideLanguageSwitcher && ( <SelectLanguage /> )}
        </center>
        {children}
      </div>
    </div>
  );
};

export default HppWrapper;
